import React from 'react'
import user1 from '../Images/userFlow/1.png'
import user2 from '../Images/userFlow/2.png'
import user3 from '../Images/userFlow/3.png'
import user4 from '../Images/userFlow/4.png'
import user5 from '../Images/userFlow/5.png'
import user1Mob from '../Images/userFlow/1mob.png'
import user2Mob from '../Images/userFlow/2mob.png'
import user3Mob from '../Images/userFlow/3mob.png'
import user4Mob from '../Images/userFlow/4mob.png'
import user5Mob from '../Images/userFlow/5mob.png'
import number1 from '../Images/userFlow/1number.svg'
import number2 from '../Images/userFlow/2number.svg'
import number3 from '../Images/userFlow/3number.svg'
import number4 from '../Images/userFlow/4number.svg'
import number5 from '../Images/userFlow/5number.svg'
import blur1 from '../Images/userFlow/blur1.png'
import blur2 from '../Images/userFlow/blur2.png'
import lineMob from '../Images/userFlow/lineMob.svg'
import ContactUsBtn from '../Components/ContactUsBtn'
import { useLanguage } from '../Helpers/LanguageContext'

function UserFlow({ setContactFormOpen }) {
    const { languageData } = useLanguage()
    const { language } = useLanguage()
    return (
        <div className='py-[20px] lg:py-[40px]'>
            <div className='max-w-[375px] lg:max-w-[unset] m-auto'>
                <p className='text-[34px] lg:text-[56px] font-bold text-center max-w-[200px] sm:max-w-[unset] m-auto mr-[30px]'>{languageData?.userFlowTitle}</p>
            </div>
            <div className='max-w-[1440px] m-auto w-full hidden lg:block'>
                <div className='xl:flex m-auto justify-center  relative '>
                    <div className='max-w-[450px] m-auto xl:m-[unset] w-full'>
                        <img className='w-[260px] m-auto xl:m-[unset] relative z-10' src={user1} alt="user1" />
                        <p className='max-w-[235px] w-full text-[18px] font-medium  xl:ml-8 mt-[-40px] leading-[28px] m-auto xl:m-[unset]'>{languageData?.userFlowItem1}</p>
                    </div>
                    <svg className='w-[340px] absolute left-[210px] top-[150px] hidden xl:block' width="332" height="76" viewBox="0 0 332 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.22656 67.5079C77.806 79.5236 249.104 83.1503 329.659 1.53171" stroke="#B485FC" stroke-width="3" stroke-linecap="round" />
                    </svg>

                    <div className='max-w-[450px] m-auto xl:m-[unset] w-full pt-6'>
                        <img className='w-[260px] m-auto xl:m-[unset] relative z-10' src={user2} alt="user2" />
                        <p className='max-w-[215px] m-auto xl:m-[unset] w-full text-[18px] font-medium xl:ml-8 mt-[-20px] leading-[28px]'>{languageData?.userFlowItem2}</p>
                    </div>
                    <svg className='w-[365px] absolute right-[400px] top-[140px] hidden xl:block' width="369" height="29" viewBox="0 0 369 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 26.97C80.4696 8.12705 263.42 -18.253 367.463 26.97" stroke="#B485FC" stroke-width="3" stroke-linecap="round" />
                    </svg>
                    <div className='max-w-[450px] m-auto xl:m-[unset] pl-6 w-full xl:flex pt-8'>
                        <img className='w-[260px] m-auto xl:m-[unset] h-[250px] relative z-10' src={user3} alt="user3" />
                        <p className='max-w-[215px] w-full text-[18px] font-medium xl:mt-[70px] m-auto xl:m-[unset] leading-[28px]'>{languageData?.userFlowItem3}</p>
                    </div>
                    <svg className='w-[260px] absolute right-[340px] top-[220px] hidden xl:block' width="265" height="264" viewBox="0 0 265 264" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 262.367C147.265 215.027 332.709 125.608 236.898 1.99999" stroke="#B485FC" stroke-width="3" stroke-linecap="round" />
                    </svg>
                </div>
                <div className='xl:flex mt-5 relative'>
                    <div className='w-[45%] flex m-auto xl:m-[unset]'>
                        <img className='w-[260px] mb-12 relative z-10' src={user4} alt="user4" />
                        <p className='max-w-[250px] w-full text-[18px] font-medium mt-[90px] leading-[28px] xl:pt-[120px]'>{languageData?.userFlowItem5}</p>
                    </div>
                    <svg className='w-[500px] absolute left-[190px] top-[120px] hidden xl:block' width="531" height="78" viewBox="0 0 531 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M529.039 2.22617C417.033 64.0601 219.526 128.388 2.16701 9.56879" stroke="#B485FC" stroke-width="3" stroke-linecap="round" />
                    </svg>
                    <div className='w-[45%] flex m-auto xl:m-[unset]'>
                        <img className='w-[260px] relative z-10' src={user5} alt="user5" />
                        <p className='max-w-[290px] w-full text-[18px] font-medium mt-[70px] leading-[28px] xl:pt-14'>{languageData?.userFlowItem4}</p>
                    </div>

                </div>
            </div>
            <div className={`relative max-w-[455px] m-auto readyOverflow mb-[-40px] lg:mb-0 pb-[70px] pt-[130px] md:pb-[130px]  lg:hidden ${language === 'US' ? 'mt-[-160px] sm:my-[-100px]' : 'mt-[-220px] sm:my-[-100px]'} `}>
                <img className=' absolute left-1/2 transform -translate-x-1/2 top-[260px]' src={lineMob} alt="lineMob" />
                <div className='flex justify-between px-3 relative mt-8'>
                    <img className='w-[174px] relative z-10' src={user1Mob} alt="user1Mob" />
                    <img className=' absolute top-[-100px] left-[-100px]' src={blur1} alt="blur1" />
                    <div>
                        <div className='flex justify-end'>
                            <img className='w-[70px] mr-[-20px]' src={number1} alt="number1" />
                        </div>
                        <p className='max-w-[170px] w-full text-[12px] font-medium leading-[20px]  text-right  relative z-10'>{languageData?.userFlowItem1}</p>
                    </div>
                </div>
                <div className='flex justify-between px-3 relative '>
                    <div>
                        <div className='flex justify-start'>
                            <img className='w-[70px] ml-[-15px]' src={number2} alt="number2" />
                        </div>
                        <p className=' w-full text-[12px] font-medium leading-[20px] text-left max-w-[170px]  relative z-10'>{languageData?.userFlowItem2}</p>
                    </div>
                    <img className=' absolute top-[-50px] right-[-50px] w-[250px]' src={blur2} alt="blur2" />
                    <img className='w-[174px] relative z-10' src={user2Mob} alt="user2Mob" />
                </div>
                <div className='flex items-center justify-between px-3 relative '>
                    <img className='w-[134px] h-[134px] relative z-10' src={user3Mob} alt="user3Mob" />
                    <img className=' absolute top-[-30px] left-[-50px] w-[250px]' src={blur2} alt="blur2" />
                    <div>
                        <div className='flex justify-end '>
                            <img className='w-[70px] mr-[-20px]' src={number3} alt="number3" />
                        </div>
                        <p className='max-w-[170px] w-full text-[12px] font-medium leading-[20px]  text-right relative z-10'>{languageData?.userFlowItem3}</p>
                    </div>
                </div>
                <div className='flex justify-between px-3 relative '>
                    <div>
                        <div className='flex justify-start'>
                            <img className='w-[70px] ml-[-15px]' src={number4} alt="number4" />
                        </div>
                        <p className=' w-full text-[12px] font-medium leading-[20px] text-left max-w-[170px]  relative z-10'>{languageData?.userFlowItem4}</p>
                    </div>
                    <img className='w-[174px] relative z-10' src={user5Mob} alt="user4Mob" />
                    <img className=' absolute top-[-90px] right-[-100px]' src={blur1} alt="blur1" />
                </div>
                <div className='flex justify-between px-3 relative'>
                    <img className='w-[174px] relative z-10' src={user4Mob} alt="user5Mob" />
                    <img className=' absolute top-[20px] left-[-40px] w-[250px]' src={blur2} alt="blur2" />
                    <div>
                        <div className='flex justify-end'>
                            <img className='w-[70px] mr-[-20px]' src={number5} alt="number5" />
                        </div>
                        <p className='max-w-[170px] w-full text-[12px] font-medium leading-[20px]  text-right  relative z-10'>{languageData?.userFlowItem5}</p>
                    </div>
                </div>
            </div>
            <ContactUsBtn setContactFormOpen={setContactFormOpen} />
        </div>
    )
}

export default UserFlow
