import React, { useState } from 'react'
import logo from '../Images/LogoHeader.svg'
import axios from 'axios';
import { useLanguage } from '../Helpers/LanguageContext'

function ContactForm({ setContactFormOpen }) {
  const { languageData } = useLanguage()

  const [selectCustom, setSelectCustom] = useState(null);
  const [selectCustomOption, setSelectCustomOption] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const selectedArray = [
    {
      option: languageData?.contactFormSelect1,
    },
    {
      option: languageData?.contactFormSelect2,
    },
    {
      option: languageData?.contactFormSelect3,
    },
  ];

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [description, setDescription] = useState("");

  const [formSended, setFormSended] = useState(false)

  const handleSubmit = async (e) => {

    e.preventDefault();

    /* ADD Validation for fields */

    if (name === "" || phone === "" || email === "" || company === "" || description === "") {
      setErrorMessage("All fields are required")
      return
    }

    if (email.indexOf('@') === -1) {
      setErrorMessage("Invalid email")
      return
    }

    const res = await axios.post('https://exodusdevelop.com:3023/send-tc-form', {
      name: name,
      phone: phone,
      email: email,
      company: company,
      description: description,
      subject: selectCustomOption
    })

    if (res.status !== 200) {
      setErrorMessage("Error sending the form")
      return
    }
    setFormSended(true)
  }
  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  return (
    <div onClick={e => setContactFormOpen(false)} className=" top-0 z-[99999] fixed  h-full w-screen bg-[#000000] bg-opacity-60 p-4  lg:flex cursor-pointer">
      <div onClick={stopPropagation} className={`relative m-auto max-w-[345px] bg-white w-full   rounded-lg p-6`}>
        <div className='w-full justify-end flex'>
          <svg className={`${formSended !== true ? 'mb-[-20px]' : 'mb-0'}`} onClick={e => setContactFormOpen(false)} width="20" height="20" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.1198 1.02949L10.086 -0.00427246L5.98764 4.09413L1.88923 -0.00427246L0.855469 1.02949L4.95387 5.1279L0.855469 9.2263L1.88923 10.2601L5.98764 6.16166L10.086 10.2601L11.1198 9.2263L7.02141 5.1279L11.1198 1.02949Z" fill="black" />
          </svg>
        </div>
        {
          formSended === false
            ? <form onSubmit={e => handleSubmit(e)}>
              <img className='w-[250px] m-auto pt-4' src={logo} alt="logo" />
              <input value={name} onChange={(e) => setName(e.target.value)} placeholder={languageData?.contactFormInput1} className='w-full h-[50px] border-2 border-[#6260E1] rounded-xl max-w-[295px] px-3 mt-4 text-[14px] outline-none' type="text" />
              <input value={phone} onChange={(e) => setPhone(e.target.value)} placeholder={languageData?.contactFormInput2} className='w-full h-[50px] border-2 border-[#6260E1] rounded-xl max-w-[295px] px-3 mt-4 text-[14px] outline-none' type="text" />
              <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder={languageData?.contactFormInput3} className='w-full h-[50px] border-2 border-[#6260E1] rounded-xl max-w-[295px] px-3 mt-4 text-[14px] outline-none' type="text" />
              <input value={company} onChange={(e) => setCompany(e.target.value)} placeholder={languageData?.contactFormInput4} className='w-full h-[50px] border-2 border-[#6260E1] rounded-xl max-w-[295px] px-3 mt-4 text-[14px] outline-none' type="text" />
              <div className={` w-full h-[50px] border-2 border-[#6260E1] rounded-xl max-w-[295px] px-3 mt-4 text-[14px] `}>
                <div onClick={(e) => setSelectCustom(!selectCustom)} className={`flex  cursor-pointer items-center justify-between h-full`}>
                  <div className="flex items-center">
                    <p className=" text-[14px] font-normal text-[#9395A9]">{selectCustomOption || languageData?.contactFormSelect0}</p>
                  </div>
                  <div className="ml-2">
                    {selectCustom ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 24" fill="none">
                        <path d="M2 17L12 7L22 17" stroke="#1E1E1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 25" fill="none">
                        <path d="M22 7.5L12 17.5L2 7.5" stroke="#1E1E1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              {selectCustom && (
                <>
                  <div
                    className={`absolute left-1/2 z-10  w-[85.5%] max-w-[600px] -translate-x-1/2 transform overflow-y-auto bg-white px-3 py-2 shadow-sm min-h-[115px] rounded-[12px] border-2 border-[#6260E1] mt-4`}
                  >
                    {selectedArray.map((item, index) => (
                      <div
                        key={index}
                        className="flex h-[25px] cursor-pointer items-center gap-1 rounded-[8px] hover:bg-slate-200"
                        onClick={(e) => {
                          setSelectCustomOption(item.option);
                          setSelectCustom(false);
                        }}
                      >
                        <label className=" ml-2 flex items-center ">
                          <p className="saira cursor-pointer text-[16px] font-normal text-[#9395A9]" id={item.option} name={item.option} value={item.option} checked={selectCustomOption === item.option}>
                            {item.option}
                          </p>
                        </label>
                      </div>
                    ))}
                  </div>
                </>
              )}
              <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder={languageData?.contactFormTextarea} className='w-full h-[115px] border-2 border-[#6260E1] rounded-xl max-w-[295px] p-3 mt-4 text-[14px] resize-none' type="text" />

              {
                errorMessage && <p className='text-red-500 text-center font-semibold mt-2'>{errorMessage}</p>
              }

              <button type='submit' className=' h-[50px] m-auto w-full bg-[#EA81B6] flex justify-center items-center text-[16px] font-semibold text-white rounded-[8px] mt-2 cursor-pointer'>
                {languageData?.contactUsBtn}
              </button>
            </form>

            : <>
              <div className='flex justify-between items-center'>
                <p className='font-bold text-left text-lg text-gray-600'>{languageData?.contactFormConfirmTitle}</p>
                
              </div>
            </>
        }

      </div>
    </div>
  )
}

export default ContactForm
