import React from 'react'
import logoEn from '../Images/LogoHeader.svg'
import logoPT from '../Images/LogoHeaderPT.svg'
import logoES from '../Images/LogoHeaderES.svg'
import heroEn from '../Images/HomeHero.png'
import heroPT from '../Images/HomeHeroPT.png'
import heroES from '../Images/HomeHeroES.png'
import heroMobEn from '../Images/HomeHeroMob.png'
import heroMobPT from '../Images/HomeHeroMobPT.png'
import heroMobES from '../Images/HomeHeroMobES.png'
import HomeStats from '../Components/HomeStats'
import ContactUsBtn from '../Components/ContactUsBtn'
import { useLanguage } from '../Helpers/LanguageContext'

function Home({ setContactFormOpen }) {

    const { languageData } = useLanguage()
    let logo;
    let hero;
    let heroMob;
    const { language } = useLanguage();
  
    switch (language) {
      case 'en':
        logo = logoEn;
        break;
      case 'pt':
        logo = logoPT;
        break;
      case 'es':
        logo = logoES; 
        break;
      default:
        logo = logoEn;
    }

    switch (language) {
      case 'en':
        hero = heroEn;
        break;
      case 'pt':
        hero = heroPT;
        break;
      case 'es':
        hero = heroES; 
        break;
      default:
        hero = heroEn;
    }

    switch (language) {
      case 'en':
        heroMob = heroMobEn;
        break;
      case 'pt':
        heroMob = heroMobPT;
        break;
      case 'es':
        heroMob = heroMobES; 
        break;
      default:
        heroMob = heroMobEn;
    }

    return (
        <div className='bg-white w-full pt-[50px] lg:pt-[50px]'>
            <div className='max-w-[1200px] px-2 m-auto relative z-10'>
               
                <img className='w-[250px] m-auto lg:m-[unset]' src={logo} alt="logo" />
                <p className='max-w-[1100px] m-auto clash font-semibold text-[24px] lg:text-[53px] text-center text-[#6260E1] lg:leading-[61px] mt-5 lg:mt-[50px] px-4 lg:px-0'>{languageData?.homeTitle}</p>
                <ContactUsBtn setContactFormOpen={setContactFormOpen} />
            </div>

            <img className='max-w-[1340px] w-full m-auto mt-[-25px] lg:mt-[-155px] hidden lg:block' src={hero} alt="hero" />
            <img className='max-w-[1340px] w-full m-auto mt-[-90px] lg:mt-[-155px] block lg:hidden' src={heroMob} alt="hero" />


            <HomeStats />

        </div>
    )
}

export default Home
