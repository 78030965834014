import React from 'react'
import { useLanguage } from '../Helpers/LanguageContext'

function ContactUsBtn({ setContactFormOpen }) {
    const { languageData } = useLanguage()
    return (
        <div onClick={e => setContactFormOpen(true)} className='max-w-[185px] lg:max-w-[230px] h-[54px] lg:h-[70px] m-auto w-full bg-[#EA81B6] flex justify-center items-center text-[16px] lg:text-[24px] font-semibold text-white rounded-[8px] mt-[30px] cursor-pointer'>
            {languageData?.contactUsBtn}
        </div>
    )
}

export default ContactUsBtn
