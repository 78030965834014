import './App.css';
import React from 'react';
import Homepage from './Pages/Homepage';
import { LanguageProvider } from './Helpers/LanguageContext';

function App() {
  return (
    <LanguageProvider>
      <Homepage />
    </LanguageProvider>
  );
}

export default App;
