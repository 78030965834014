import React from 'react'
import { useLanguage } from '../Helpers/LanguageContext'
import iconStats from '../Images/statsIcon.png'

function HomeStats() {
    const { languageData } = useLanguage()
    return (
        <div className='w-full bg-[#EA81B6] pt-2 lg:pt-6 pb-2 lg:pb-4 relative'>
            <img className='absolute right-3 top-[-20px] lg:top-[-40px] w-[26px] lg:w-[80px]' src={iconStats} alt="iconStats" />
            <div className='max-w-[1400px] m-auto w-full flex px-6 lg:px-2 justify-between'>
                <div className='lg:mt-6 w-[90px] lg:w-[unset]'>
                    <p className='clash font-semibold text-[20px] lg:text-[75px] leading-[20px] lg:leading-[unset] text-center text-white max-w-[40px] m-auto lg:max-w-[unset]'>{languageData?.homeStats1Title}</p>
                    
                </div>
                <div className='lg:mt-6'>
                    <p className='clash font-semibold text-[20px] lg:text-[75px] text-center text-white lg:leading-[90px]'>100,000+</p>
                    <p className='clash font-semibold text-[12px] lg:text-[24px] text-center text-white max-w-[90px] lg:max-w-[unset] w-full leading-4'>{languageData?.homeStats2Title}</p>
                </div>
                <div className='lg:mt-6'>
                    <p className='clash font-semibold text-[20px] lg:text-[75px] text-center text-white lg:leading-[90px]'>85%</p>
                    <p className='clash font-semibold text-[12px] lg:text-[24px] text-center text-white max-w-[115px] lg:max-w-[unset] w-full leading-4'>{languageData?.homeStats3Title}</p>
                </div>
            </div>

        </div>
    )
}

export default HomeStats
