import React from 'react'
import QuestionsBlock from '../Components/QuestionsBlock'
import { useLanguage } from '../Helpers/LanguageContext'

function Faq() {
    const { languageData } = useLanguage()
    return (
        <div className='py-[20px] lg:py-[50px] w-full px-3 lg:px-0'>
            <div className='max-w-[1090px] w-full m-auto'>
                <p className='text-[18px] font-medium tracking-[5px] text-[#EA81B6]'>{languageData?.faqTitle}</p>
                <p className='max-w-[500px] w-full text-[23px] lg:text-[48px] font-bold lg:leading-[48px]'>{languageData?.faqSubTitle}</p>
                <QuestionsBlock reverse={'lg:mr-2'} col={'flex-col md:flex-row'} title1={languageData?.faqBlok1Title} content1={languageData?.faqBlok1SubTitle}
                    title2={languageData?.faqBlok2Title} content2={languageData?.faqBlok2SubTitle}
                />
                 <QuestionsBlock reverse2={'lg:mr-2'} col={'flex-col md:flex-row-reverse'} title1={languageData?.faqBlok4Title} content1={languageData?.faqBlok4SubTitle}
                  title2={languageData?.faqBlok3Title} content2={languageData?.faqBlok3SubTitle}
                />
                <QuestionsBlock reverse={'lg:mr-2'} col={'flex-col md:flex-row'} title1={languageData?.faqBlok5Title} content1={languageData?.faqBlok5SubTitle}
                    title2={languageData?.faqBlok6Title} content2={languageData?.faqBlok6SubTitle}
                />
            </div>

        </div>
    )
}

export default Faq
