import React, { useState } from 'react'
import Home from './Home'
import HowWorks from './HowWorks'
import About from './About'
import UserFlow from './UserFlow'
import Faq from './Faq'
import Ready from './Ready'
import Footer from './Footer'
import ContactForm from '../Components/ContactForm'
import Menu from '../Components/Menu/Menu'

function Homepage() {
  const [contactFormOpen, setContactFormOpen] = useState(false)
  return (
    <>
      <Menu />
      <Home setContactFormOpen={setContactFormOpen} />
      <HowWorks setContactFormOpen={setContactFormOpen} />
      <About setContactFormOpen={setContactFormOpen} />
      <UserFlow setContactFormOpen={setContactFormOpen} />
      <Faq />
      <Ready setContactFormOpen={setContactFormOpen} />
      <Footer />
      {
        contactFormOpen === true && <ContactForm setContactFormOpen={setContactFormOpen} />
      }
    </>
  )
}

export default Homepage