import React from 'react'

function QuestionsBlock({ col, title1, content1, title2, content2, reverse, reverse2}) {
  return (
    <div className={`flex ${col} justify-between mt-4 lg:mt-8`}>
      <div className={`w-full ${reverse}  bg-[#EA81B6] rounded-[8px] p-[15px] lg:p-[30px]`}>
        <p className={` font-semibold text-[28px] text-white leading-[32px] lg:leading-[28px]`}>{title1}</p>
        <p className=' font-medium text-[18px] text-white leading-[28px] mt-3 lg:mt-2'>{content1}</p>
      </div>
      <div className={`w-full ${reverse2}  p-[15px] lg:p-[30px] rounded-[8px] shadow-md lg:shadow-[unset] mt-6 lg:mt-0`}>
        <p className={` font-semibold text-[28px] text-black leading-[32px] lg:leading-[28px]`}>{title2}</p>
        <p className=' font-medium text-[18px] text-[#808080] leading-[28px] mt-3 lg:mt-2'>{content2}</p>
      </div>
    </div>
  )
}

export default QuestionsBlock
