import React from 'react'
import blur from '../Images/aboutBlur.png'
import heroEn from '../Images/aboutHero.png'
import heroPT from '../Images/aboutHeroPT.png'
import heroES from '../Images/aboutHeroES.png'
import logo from '../Images/aboutLogo.svg'
import ContactUsBtn from '../Components/ContactUsBtn'
import { useLanguage } from '../Helpers/LanguageContext'

function About({ setContactFormOpen }) {
    const { languageData } = useLanguage()
    const { language } = useLanguage();

    let hero;
    
    switch (language) {
        case 'en':
          hero = heroEn;
          break;
        case 'pt':
          hero = heroPT;
          break;
        case 'es':
          hero = heroES; 
          break;
        default:
          hero = heroEn;
      }

    return (
        <div className='bg-[#222337] w-full relative py-[60px] px-3 lg:px-0'>
            <img className='w-[250px] lg:w-[300px] m-auto pb-[30px]' src={logo} alt="logo" />
            <div className='flex flex-col-reverse lg:flex-row justify-between items-center max-w-[1440px] m-auto relative z-10'>
                <div className='max-w-[525px] w-full'>
                    <p className='text-[35px] font-semibold text-white text-center py-2 lg:py-0'>{languageData?.aboutTitle}</p>
                    <p className='text-[12px] lg:text-[22px] font-normal text-white leading-4 lg:leading-7 px-2 lg:px-0 mt-4'>{languageData?.aboutSubTitle1}</p>
                    <p className='text-[12px] lg:text-[22px] font-normal text-white leading-4 lg:leading-7 px-2 lg:px-0 mt-4'>{languageData?.aboutSubTitle2}</p>
                    <p className='text-[12px] lg:text-[22px] font-normal text-white leading-4 lg:leading-7 px-2 lg:px-0 mt-4'>{languageData?.aboutSubTitle3}</p>
                    <ContactUsBtn setContactFormOpen={setContactFormOpen} />
                </div>
                <img className='max-w-[670px] w-full' src={hero} alt="hero" />
            </div>
            <img className=' right-0 absolute h-full top-0' src={blur} alt="blur " />
        </div>
    )
}

export default About
