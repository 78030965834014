import React from 'react'
import blur from '../Images/readyBlur.png'
import blur2 from '../Images/readyBlur2.png'
import hero from '../Images/readyHero.png'
import apple from '../Images/appleLogo.svg'
import ContactUsBtn from '../Components/ContactUsBtn'
import { useLanguage } from '../Helpers/LanguageContext'

function Ready({ setContactFormOpen }) {
    const { languageData } = useLanguage()
    return (
        <div className='w-full pb-[40px] lg:pb-[100px]'>
            <div className='max-w-[1110px] m-auto w-full relative'>
                <p className=' text-[#585858] text-[24px] lg:text-[48px] font-bold text-center'>{languageData?.readyTitle}</p>
                <img className=' absolute w-[500px] left-1/2 transform -translate-x-1/2 z-[-1] top-[-120px] hidden lg:block' src={blur} alt="blur" />
                <img className=' absolute w-[200px] left-1/2 transform -translate-x-1/2 z-[-1] top-[-50px] lg:hidden block' src={blur2} alt="blur" />
                <ContactUsBtn setContactFormOpen={setContactFormOpen} />
                {/*<div className='max-w-[700px] w-full relative px-4 lg:px-0'>
                    <p className='capitalize text-[#585858] text-[16px] lg:text-[48px] font-bold'>ready to get started?</p>
                    <p className='capitalize text-[#585858] text-[6px] lg:text-[18px] lg:leading-[29px] font-regular max-w-[170px] lg:max-w-[540px]'>Risus habitant leo egestas mauris diam eget morbi tempus vulputate.</p>
                    <button className='max-w-[70px] lg:max-w-[210px] w-full bg-white rounded-[4px] text-[6px] lg:text-[18px] font-medium py-1 lg:py-3 flex justify-center items-center mt-4'>Download App <img className='w-[9px] lg:w-[25px] ml-1 lg:ml-2 lg:mb-1' src={apple} alt="apple" /></button>
                    <img className=' absolute left-[-30px] lg:left-[-100px] top-[-80px] lg:top-[-140px] z-[-1]' src={blur} alt="blur" />
                    <svg className=' absolute right-[70px] lg:right-6 top-[40px] lg:top-[100px] Z-[-1] w-[22px] lg:w-[62px]' viewBox="0 0 62 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M31 0L32.5047 29.9289L61.4338 22.1115L33.4347 32.7911L49.8091 57.8885L31 34.56L12.1909 57.8885L28.5653 32.7911L0.566191 22.1115L29.4953 29.9289L31 0Z" fill="black" />
                    </svg>
                </div>
                <img className='max-w-[200px] md:max-w-[490px] xl:max-w-[590px] w-full ml-[-80px] lg:ml-0 xl:mr-[-50px] mr-2' src={hero} alt="readyApp" />
                */}
            </div>
        </div>
    )
}

export default Ready
