import React from 'react'
import phoneEn from '../Images/HowWorksPhone.png'
import phonePT from '../Images/HowWorksPhonePT.png'
import phoneES from '../Images/HowWorksPhoneES.png'
import phoneMobEn from '../Images/HowWorksPhoneMob.png'
import phoneMobPT from '../Images/HowWorksPhoneMobPT.png'
import phoneMobES from '../Images/HowWorksPhoneMobES.png'
import logo from '../Images/howLogo.svg'
import star from '../Images/howStar.svg'
import romb from '../Images/howRomb.svg'
import cube from '../Images/howCube.svg'
import ContactUsBtn from '../Components/ContactUsBtn'
import { useLanguage } from '../Helpers/LanguageContext'

function HowWorks({ setContactFormOpen }) {
  const { languageData } = useLanguage()
  const { language } = useLanguage()

  let phone;
  let phoneMob;

  switch (language) {
    case 'en':
      phone = phoneEn;
      break;
    case 'pt':
      phone = phonePT;
      break;
    case 'es':
      phone = phoneES;
      break;
    default:
      phone = phoneEn;
  }

  switch (language) {
    case 'en':
      phoneMob = phoneMobEn;
      break;
    case 'pt':
      phoneMob = phoneMobPT;
      break;
    case 'es':
      phoneMob = phoneMobES;
      break;
    default:
      phoneMob = phoneMobEn;
  }

  return (
    <div className='w-full bg-white pb-[30px] xl:pb-0 pt-[30px] xl:pt-[100px]'>
      <div className='max-w-[1300px] m-auto  w-full px-3 xl:flex justify-between'>
        <img className='max-w-[600px] w-full mr-[-60px] hidden xl:block' src={phone} alt="phone" />
        <div className='flex xl:hidden justify-center'>
          <p className={`${language !== 'US' ? 'text-[22px]' : ' text-[30px]'}   clash font-bold`}>{languageData?.howTitle}</p>
          <img className='w-[120px] xl:w-[315px] mx-2' src={logo} alt="coin" />
          <p className='text-[30px] xl:text-[48px] clash font-bold'>{languageData?.howTitleSpan}</p>
        </div>
        <img className='max-w-[600px] m-auto w-full xl:hidden block mt-[-50px] mb-[-70px]' src={phoneMob} alt="phoneMob" />
        <div className='max-w-[760px] w-full mt-12'>
          <div className='xl:flex hidden items-end'>
            <p className='text-[30px] xl:text-[48px] clash font-bold'>{languageData?.howTitle}</p>
            <img className='w-[140px] xl:w-[315px] mx-2 mb-1' src={logo} alt="coin" />
            <p className='text-[30px] xl:text-[48px] clash font-bold'>{languageData?.howTitleSpan}</p>
          </div>
          <div>
            <div className='flex items-center xl:mt-8'>
              <img className='w-[16px] xl:w-[28px] h-[16px] xl:h-[28px] mr-2' src={star} alt="star" />
              <p className='text-[16px] xl:text-[21px] font-semibold'>{languageData?.howItem1Title}</p>
            </div>
            <p className='text-[12px] xl:text-[21px] font-medium text-[#807F7F] mt-1 xl:mt-4 '>
              {languageData?.howItem1SubTitle}
            </p>
          </div>
          <div>
            <div className='flex items-center mt-2 xl:mt-8'>
              <img className='w-[16px] xl:w-[28px] h-[16px] xl:h-[28px] mr-2' src={romb} alt="romb" />
              <p className='text-[16px] xl:text-[21px] font-semibold'>{languageData?.howItem2Title}</p>
            </div>
            <p className='text-[12px] xl:text-[21px] font-medium text-[#807F7F] mt-1 xl:mt-4 '>
              {languageData?.howItem2SubTitle}
            </p>
          </div>
          <div>
            <div className='flex items-center mt-2 xl:mt-8'>
              <img className='w-[16px] xl:w-[28px] h-[16px] xl:h-[28px] mr-2' src={cube} alt="cube" />
              <p className='text-[16px] xl:text-[21px] font-semibold'>{languageData?.howItem3Title}</p>
            </div>
            <p className='text-[12px] xl:text-[21px] font-medium text-[#807F7F] mt-1 xl:mt-4 '>
              {languageData?.howItem3SubTitle}
            </p>
          </div>
          <ContactUsBtn setContactFormOpen={setContactFormOpen} />
        </div>
      </div>
    </div>
  )
}

export default HowWorks
